<template>
  <div class="testing-history-chart" @click="openTestStatusView(row['name'])">
      <XChart v-if="Boolean(chartValue)" :value="chartValue"  />
  </div>
</template>

<script>
import { defineComponent } from "vue"
import XChart from '@/components/basic/XChart.vue';
import chartService from "@/js/services/ChartService";

export default defineComponent({
  name: 'TestingHistoryChart',
  components: {
    XChart,
  },

  props: {
    row: Object,
    resolution: Number,
    confine: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      chartValue: {},
    }
  },
  created() {
    if (this.row) {
      this.getSchedulingResults();
    }
  },
  methods: {
    openTestStatusView(scheduledTestName) {
      this.$store.commit('setTestStatusSearch', scheduledTestName);
      this.safeClick(`/?f=testing&f2=testStatus`);
    },
    getSchedulingResults() {
      chartService.getSchedulingResults(this.row['id'], '-24h', this.resolution, (schedulingResults) => {
        delete schedulingResults.title;
        if (schedulingResults.series !== null) {
          schedulingResults.series.splice(5, 1);
        }
        schedulingResults.color.splice(5, 1);
        if (schedulingResults.series !== null) {
          for (const seriesItem of schedulingResults.series) {
            seriesItem.type = 'bar';
            seriesItem.stack = 'total';
          }
          const resultError = schedulingResults.series[2];
          schedulingResults.series.splice(2, 1);
          schedulingResults.series.push(resultError);
        }
        const resultErrorColor = schedulingResults.color[2];
        schedulingResults.color.splice(2, 1);
        schedulingResults.color.push(resultErrorColor);

        if (this.confine) {
          schedulingResults.tooltip = {
            ...schedulingResults.tooltip,
            confine: true,
          }
        }

        this.chartValue = {
          options: schedulingResults
        };
      });
    }
  },

  watch: {
    resolution: {
      deep: true,
      immediate: true,
      handler() {
        this.getSchedulingResults();
      }
    }
  },
})

</script>

<style lang="scss">
.testing-history-chart {
  width: 360px;
  height: 60px;
}
</style>
